<template>
  <div class="playground-generals-inf-container container-fluid">
    <b-card class="playground-generals-inf-card">
      <div class="row-style">
        <d-body-nav-bar
            :tabs="[]"
            position="float-right"
            :title="'views.playground.steps.step-1.informations.activities.title'"
            :title-class="'topnav-title-red-light'"
        />
      </div>
      <d-table
          :tablefields="tableFields"
          :items="selectedBlockPrices"
          :per-page="itemsPerPage"
          :totalRows="totalRows"
          :is-busy="isBusy"
          :current-page="currentPage"
          @current-page:update="onCurrentPageUpdate"
          @on:minus-icon-click="onBlockPriceRemove"
      >
      </d-table>
    </b-card>
  </div>
</template>
<script>

import { getPlaygroundPricesFilteredByActivities } from "@api/doinsport/services/timetable/blocks/price/price.api";
import Price from "@/classes/doinsport/Price";
import {getDuration} from "@/utils/date";

export default {
  props: {
    playground: {
      type: Object,
      default: this,
    },
    nicheActivitiesSelection: {
      type: Array,
      default: this,
    },
    isPlaygroundPrices: {
      type: Boolean,
      default: false,
    },
    playgroundTimetables: {
      type: Array,
      default: this,
    },
    pricesListSelectionStep3: {
      type: Array,
      default: this,
    },

  },
  data: () => ({
    nicheModalActivityKey: 1,
    items: [],
    itemsPerPage: 10,
    currentPage: 1,
    pagination: 1,
    isBusy: false,
  }),
  computed: {
    selectedActivities() {
      return this.playground.activities.length > 0;
    },
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    selectedBlockPrices () {
      return this.$store.getters["playgrounds/getSelectedBlockPrices"];
    },
    totalRows () {
      return this.$store.getters["playgrounds/getBlockPricesTotalItems"];
    },
    tableFields() {
      return [
        {
          key: "minusIcon",
          class: 'selection-checkbox-td text-center',
          label: this.$t('components.doinsport.table.selection'),
          sortable: false
        },
        {
          key: "name",
          label: this.$t("views.playground.steps.step-1.informations.activities.table.wording"),
          sortable: false
        },
        {
          key: "activity",
          label: this.$t("views.playground.steps.step-1.informations.activities.table.activity"),
          sortable: false
        },
        {
          key: "maxParticipantsCountLimit",
          class: this.alignCellsToLeft,
          label: this.$t("views.playground.steps.step-1.informations.activities.table.nb-players"),
          sortable: false
        },
        {
          key: "nicheDuration",
          label: this.$t("views.playground.steps.step-1.informations.activities.table.duration"),
          sortable: false
        },
        {
          key: "pricePerParticipant",
          class: this.alignCellsToLeft,
          label: this.$t("views.playground.steps.step-1.informations.activities.table.public-price"),
          sortable: false
        },
        {
          key: "otherPrices",
          label: this.$t("views.playground.steps.step-1.informations.activities.table.other-prices"),
          sortable: false
        },
      ];
    }
  },
  methods: {
    onBlockPriceRemove(row) {
      this.$store.dispatch('playgrounds/removeBlockPrice', row);
      this.$emit('reload-blockPrices');
    },
    onCurrentPageUpdate(page) {
      this.currentPage = page;
    },
    getPricesRequest(itemsPerPage, page) {
      return getPlaygroundPricesFilteredByActivities(itemsPerPage, page, this.$route.params.id, this.playground.activities);
    },
    loadPrices(itemsPerPage, page) {
      this.isBusy = true;
      let totalItems = 0;
      const allItems = [];

      this.getPricesRequest(50, page)
          .then((response) => {
            totalItems = response.data['hydra:totalItems'];
            for (const price of response.data['hydra:member']) {
              const priceClass = new Price(price, {serialize: false, deserialize: true});

              Object.assign(priceClass, { minusIcon: true });
              Object.assign(priceClass, {nicheDuration: getDuration(priceClass.duration)});
              Object.assign(priceClass, {otherPrices: this.$t('general.actions.details')});

              allItems.push(priceClass);
            }
          })
          .finally(() => {
            this.isBusy = false;
            this.$store.dispatch('playgrounds/saveBlockPricesSelection', allItems);
            this.$emit('reload-blockPrices');
          })
      ;
    }
  },
  mounted() {
    const countSelectedBlockPrices = this.$store.getters["playgrounds/getBlockPricesTotalItems"];

    if (countSelectedBlockPrices === 0) {
      this.loadPrices(this.itemsPerPage, this.currentPage);
    }
  }
}
</script>
<style scoped>
.playground-generals-inf-card .card-body {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 240px;
  padding: 1.25rem;
}

.playgraound-general-title {
  text-align: left;
  font: normal normal 900 22px Avenir;
  letter-spacing: 0px;
  color: #FF0101;
  opacity: 1;
}

.playground-generals-inf-container .card {
  box-shadow: 0px 10px 16px #E2E1E6;
  border-radius: 8px;
}
</style>
